<template>
  <b-row align-h="center">
    <b-col lg="8">
      <b-row>
        <b-col lg="12">
          <b-row >
            <b-col lg="7">
              <b-form-group label="Facturas">
                <b-form-select v-model="crud.form.voucher" :options="arr.invoice" size="sm"></b-form-select>
              </b-form-group>          
            </b-col>    
            <b-col lg="4">
              <b-form-group label="Importe">
                <b-input-group :prepend="crud.form.symbol" size="sm">
                  <b-form-input type="number"                              
                                v-model="crud.form.amount"                                
                                min="0"
                                step="0.01"
                                placeholder="Ingresar un Importe">
                  </b-form-input>
                </b-input-group>                
              </b-form-group>          
            </b-col>                              
            <b-col lg="1">
              <b-button variant="success" class="pull-right crud-sales-inc-add-invoice" size="sm" @click="addInvoice()" title="Agregar Factura">
                <i class="fa fa-plus"></i>
              </b-button>
            </b-col>                    
          </b-row>
        </b-col>  

        <b-col lg="12">      
          <b-table class="mb-0"
                style="min-height:130px;"
                responsive
                head-variant="dark"
                :hover="true"
                :small="true"
                :fixed="true"
                :items="table.items"
                :fields="table.fields"                            
                :busy="table.isBusy"                     
                v-if="table.items.length || table.isBusy">   
              
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"                    
                :key="field.key"
                :style="{ width: field.width }"
              >
            </template> 

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Cargando...</strong>
              </div>
            </template>           
            
            <template v-slot:cell(date)="data">
              {{moment(data.item.voucher.date).format('DD/MM/YYYY')}}
            </template>                         
                          
            <template v-slot:cell(invoice)="data">
              {{data.item.reference}}
            </template>                         

            <template v-slot:cell(amount)="data">
              {{Intl.NumberFormat('es-AR',{style:'currency',currency: currency.code}).format(data.item.amount)}}              
            </template>            

            <template v-slot:cell(f_action)="data">
              <b-dropdown right text="" size="sm" variant="outline-dark" class="pull-right">
                
                <b-dropdown-header>Acciones</b-dropdown-header>
                <b-dropdown-item @click="removeInvoice(data.index)">
                  <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                </b-dropdown-item>                     

              </b-dropdown>
            </template>
          </b-table>
          <b-alert show variant="warning" v-else>
            No hay facturas para mostrar
          </b-alert>
        </b-col>
      </b-row>
    </b-col>
  </b-row>  
</template>
<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'  
  import moment from 'moment'

  export default {
    props:{
      customer_id:{
        type: Number,
        required: true
      },  
      point_sale_id:{
        type: Number,
        required: true
      }, 
      details:{
        type: Array,
        required: true
      }, 
      amount: {
        type: Number,
        required: true
      },     
      currency: {
        type: Object,
        required: true
      }                         
    },
    data: () => {
      return {    
        crud: {
          form: {
            voucher: null,
            amount: 0,
            symbol: '$',
            details: [],
          }
        },
        table : {
          items: [],
          fields: [
            {key: 'date', label: 'Fecha', class:'text-left align-middle', width:"20%"},
            {key: 'invoice', label: 'Factura', class:'text-left align-middle', width:"45%"},            
            {key: 'amount', label: 'Importe', class:'text-left align-middle', width:"25%"},            
            {key: 'f_action', label:'', class:'text-right align-middle', width:"10%"},            
          ],
          isBusy: false,
        },                   
        arr: {
          invoice: [],
        },
      }
    },
    mounted(){      
      this.obtenerVouchersByCliente()     
      this.loadInvoice()
      this.getAmountAvailable()
    },
    watch: {        
      'customer_id': function (newQuestion, oldQuestion) {         
        this.obtenerVouchersByCliente()
      },
      'point_sale_id': function (newQuestion, oldQuestion) {         
        this.obtenerVouchersByCliente()
      },     
      'amount': function (newQuestion, oldQuestion) {         
        this.getAmountAvailable()
      },      
      'currency': function (newQuestion, oldQuestion) {          
        this.crud.form.symbol = newQuestion.symbol
        this.obtenerVouchersByCliente()
      },            
    },
    computed: {
      getTotal() {
        var sum = 0
        if(this.crud.form.details) {        
          this.crud.form.details.forEach(element => {
            sum = parseFloat(sum) + parseFloat(element.amount)
          });
        }        
        return sum
      }
    },
    methods: {
      obtenerVouchersByCliente() {  
        this.table.items = []      
        this.crud.form.details = []      
        this.arr.invoice = []
        if(this.customer_id && this.point_sale_id && this.currency) {
          var result = serviceAPI.filtrarPendienteImputar({
            'customers_id':this.customer_id, 
            'points_sales_id':this.point_sale_id,
          })        
          result.then((response) => {
            var data = response.data                
            if(data) {
              data.forEach(element => {              
                this.arr.invoice.push({
                  value: element,
                  text: element.type_voucher.name + ' ' + element.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0') + ' | ' + moment(element.date).format('DD/MM/YYYY') + ' | ' + Intl.NumberFormat('es-AR',{style:'currency',currency: this.currency.code}).format(element.amount_total),
                })                
              });
            }           
          })  
        }
      },      
      loadInvoice() {
        if(this.details) {
          this.table.isBusy = true        
            this.crud.form.details = []
            this.details.forEach(element => {      
              var data = element
              if( element.voucher ) {
                data = element.voucher
              }
              this.crud.form.details.push({        
                voucher: data,
                reference: data.type_voucher.name + ' ' + data.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.number.toString().padStart(8,'0'),
              })                    
            });
                      
            if(this.crud.form.details) {
              this.table.items = this.crud.form.details
              this.$emit('getInvoice', this.crud.form.details) 
            } else {
              this.table.items = null
              this.$emit('getInvoice', null) 
            }
            this.crud.form.voucher = null          
          this.table.isBusy = false          
        }
      },
      addInvoice() {       
        // valido el importe del comprobante
        if(!parseFloat(this.crud.form.amount)) {
          this.$awn.alert("El importe debe ser mayor a cero")
          return false                    
        }        

        // valido el importe del comprobante
        if(parseFloat(this.crud.form.amount) > parseFloat(this.crud.form.voucher.amount_total)) {
          this.$awn.alert("El importe ingresado supera el total del comprobante seleccionado. Total del comprobante selccionado: $" + this.crud.form.voucher.amount_total)
          return false                    
        }

        // valido el total del importe
        if( (parseFloat(this.getTotal) + parseFloat(this.crud.form.amount)) > parseFloat(this.amount)) {
          this.$awn.alert("El importe total de los comprobantes es mayor a la nota de crédito")
          return false          
        }               

        // valido si ya fue agregada la factura
          var status = false
          var invoice = ''
          this.crud.form.details.forEach(element => {   
            if(element.voucher.id == this.crud.form.voucher.id) {
              status = true
              invoice = element.voucher.type_voucher.name + ' ' + element.voucher.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.voucher.number.toString().padStart(8,'0')
            }
          });        

          if(status) {
            this.$awn.alert("La factura " + invoice + " ya fue agregada")
            return false
          }
        // fin
                
        this.table.isBusy = true        
          this.crud.form.details.push({        
            voucher: this.crud.form.voucher,
            reference: this.crud.form.voucher.type_voucher.name + ' ' + this.crud.form.voucher.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.crud.form.voucher.number.toString().padStart(8,'0'),
            amount: this.crud.form.amount,
          })

          this.getAmountAvailable()

          if(this.crud.form.details) {
            this.table.items = this.crud.form.details
            this.$emit('getInvoice', this.crud.form.details) 
          } else {
            this.table.items = null
            this.$emit('getInvoice', null) 
          }
          this.crud.form.voucher = null          
        this.table.isBusy = false
      },      
      removeInvoice(index) {
        this.crud.form.details.splice(index, 1)   
        this.getAmountAvailable()        
        this.$emit('getInvoice', this.crud.form.details)      
      },  
      getAmountAvailable() {
        var available = this.amount
        if(this.crud.form.details) {        
          this.crud.form.details.forEach(element => {
            available = parseFloat(available) - parseFloat(element.amount)
          });
        }
        this.crud.form.amount = parseFloat(Math.abs(available)).toFixed(2)        
      }      
    }
  }
</script>
<style>
  .crud-sales-inc-add-invoice {
    margin-top: 30px;
  }
</style>
